@import var

.form-container
  background-color: $white
  color: $deep-space
  flex: 1

  +l
    box-shadow: $shadow-card

    &.content
      padding: $gutter

  +s
    margin-top: $gutter * 2

    .sales-form &
      padding: $gutter / 2
      margin: $gutter (-$gutter / 2) 0

  .title-label
    color: $deep-space
    margin-bottom: 0