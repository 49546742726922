@import var

main.platform
  .hero
    +l
      .texts
        flex: cols(8) 0 0

      .graphic
        flex: cols(4) - $gutter * 2 0 0

    .subtitle
      font-weight: 500

  .overview
    +l
      &:after
        content: ''
        pointer-events: none
        position: absolute
        right: 0
        width: size(270)
        height: size(534)
        bottom: size(-534 / 2)
        z-index: 1
        background-image: url('../media/platform-side.png')

  .benefits
    ul
      +l
        +flex-columns(3, $gap: auto)

    li
      margin-top: $gutter

    .icon
      width: $gutter * 2
      height: $gutter * 2
      margin-bottom: $gutter / 2

      img, svg
        height: 100%
        width: auto

  .features.in-view
    --url: url('../media/svg-sign.svg')

  .features
    +l
      &:before, &:after
        content: ''
        pointer-events: none
        position: absolute

      &:before
        right: 0
        width: size(360)
        height: size(53)
        top: size(33)
        z-index: 1
        background-image: url('../media/platform-features.png')

      &:after
        bottom: -$gutter-big
        left: -$gutter-big
        width: size(540)
        height: size(354)
        z-index: -1
        background-image: var(--url)

    .text-deep-space
      font-weight: 500
      margin-bottom: $gutter * 2

    .card
      .text
        color: $gray-dark
        font-weight: 400

  .addons
    +l
      .articles
        +flex-columns($gap: auto)

      article.rich
        flex: 100% 0 0
        margin: 0

    a
      margin-top: $gutter

    article
      &:not(:first-of-type)
        margin-top: $gutter

    .articles
      margin-top: $gutter * 2