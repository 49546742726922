@import ../var
@import ../media

=horizontal-list
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  align-items: center

=content-width
  padding: 0 $padding-s
  max-width: $grid-width

  +medium
    padding: 0 $padding-m

  +large
    padding: 0 $content-external-gutter

=hidden
  opacity: 0
  pointer-events: none

=visually-hidden
  position: absolute
  clip: rect(1px, 1px, 1px, 1px)
  clip-path: inset(0px 0px 99.9% 99.9%)
  overflow: hidden
  height: 1px
  width: 1px
  padding: 0
  border: 0

=visually-hidden-with-opacity
  +visually-hidden
  opacity: .01

=unhidden
  opacity: 1
  transform: none
  pointer-events: auto