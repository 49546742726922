$duration-quick: 0.15s
$duration-short: 0.3s
$duration-mid: 0.5s
$duration-main: 0.8s
$duration-long: 1.5s

$ease: ease-out

$ease-spring: cubic-bezier(.69, -.15, 0, 1.33)
$ease-spring-flat: cubic-bezier(.69, 0, 0, 1)

$transition-long: all $duration-long $ease
$transition-main: all $duration-main $ease
$transition-mid: all $duration-mid $ease
$transition-short: all $duration-short $ease
$transition-quick: all $duration-quick $ease
$transition-spring: all $duration-main $ease-spring
$transition-spring-flat: all $duration-main $ease-spring-flat