@import var

main.singular
  article.content
    > h2, > h3, > h4, > h5, > h6, > blockquote
      color: $deep-space
      font-weight: 500

    > h2, > h3, > h4, > h5, > h6, > img
      margin-top: $gutter
      margin-bottom: $gutter / 2

    > h2
      @extend .title-small

    > h3
      @extend .text-large

  .hero
    .texts:only-child
      flex-grow: 1
      max-width: cols(8)

    .subtitle
      margin-top: $gutter
      color: $deep-space

      &, b, strong
        font-weight: 500

    .image
      background-position: 0 0

      +l
        background-position: right top

  .text-image-block
    +s
      .content
        flex-direction: column

  .post-main
    display: flex
    flex-direction: column

    +l
      flex-direction: row-reverse
      justify-content: flex-end

    .post-content
      flex: cols(8) 0 0
      max-width: cols(8)

    aside
      flex: cols(3) 0 0
      margin-right: cols(1)
      margin-top: var(--padding-big-size)
      padding-top: $gutter-big * 2

      +s
        display: none

  .share-links
    margin-top: $gutter

    +l
      display: flex

    .link
      min-width: cols(3)
      margin-right: $gutter
      margin-top: $gutter / 2

  .form-container
    margin-top: $gutter

  img.size-medium
    max-width: 300px

.post-content
  .breakout
    +l
      width: $grid-width - $gutter * 2

      &.full
        width: $grid-width + $gutter * 4
        margin-left: -$gutter-big

.with-quote .breakout
  margin-left: cols(-4) + $gutter * 3