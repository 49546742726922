.credit-logo
  display: block

  a
    text-decoration: none
    display: inline

    path, polygon
      fill: currentColor

  svg
    width: 1.5em
    display: inline-block
    margin: 0 .25em -.33em .33em
    vertical-align: top