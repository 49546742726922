@import var

main.home
  h1
    padding-bottom: 1em

  .hero
    .image
      width: size(396)
      height: size(465)

      +s
        background-size: contain
        height: 50vh
        max-width: 100%
        margin-top: $gutter

      +l
        transform: translateY(-$gutter)

    +small
      h1
        font-size: 10.5vw

      .text-large
        font-size: 5vw

      > .content
        overflow: hidden
        padding-bottom: $gutter * 2
        margin-bottom: -$gutter * 2

    a
      margin-top: $gutter

      +l
        margin-top: $gutter * 2

  .trusted-by
    margin-top: $gutter

  .adjectives
    position: absolute
    height: 1em
    line-height: 1
    display: inline-block
    margin-left: .25em

    +small
      display: block
      margin-left: 0

    .adjective
      position: absolute

      span
        transition: $transition-quick
        +stagger(10, $duration-quick / 2)
        margin-left: 0
        margin-right: 0

      &:not(.in-view)
        span
          transition: $transition-quick, margin 0s $duration-quick
          +hidden
          margin-left: -.1em
          margin-right: .1em

        .super-underline
          +hidden

  .updates
    +l
      z-index: 1

      + .home-platform
        margin-top: -$size-256
        padding-top: $size-256

  .benefits
    li
      margin-bottom: $gutter / 2
      max-width: cols(5)
      position: relative

      +l
        display: flex
        margin-left: cols(1)

    .icon, .text-large
      height: $gutter * 1.5

    .icon
      $width: size(28)
      width: $width
      margin-right: $gutter / 2
      +flex-center
      margin-top: $gutter

      +l
        margin-top: 0
        position: absolute
        left: -($width + $gutter / 2)

  .industries
    padding: var(--padding-big)
    overflow: hidden

    .graphic-element-container
      min-width: 1400px

    .title-label
      opacity: .5

    ul
      +l
        margin-top: $gutter-big

    li
      padding: $gutter 0
      border-top: solid size(1) transparentize($white, .75)

      +l
        width: cols(5)

    .texts
      transition: $transition-quick
      +scroll-height-auto
      margin-top: $gutter


      +l
        position: absolute
        top: $gutter-big
        bottom: $gutter
        width: cols(5) - $gutter / 2
        right: $gutter
        margin-top: 0
        +flex-center($items: flex-start)

        > *
          transform: translateZ(0)

    h3
      font-weight: 600
      transition: $transition-quick
      margin-left: 0

    h4
      margin-bottom: $gutter

    a
      margin-top: $gutter

    input:not(:checked) ~ .texts
      +hidden

      +s
        height: 0

      .super-underline
        &:after
          --url: none

    input:checked ~ .texts
      +l
        > *
          animation: industries-in $duration-mid $ease 1 backwards
          @keyframes industries-in
            from
              opacity: 0
              transform: translateY($gutter / 2)
          +stagger(3, $duration-quick, $type: animation)

    input:not(:checked) ~ h3
      opacity: .25

      &:hover
        opacity: .5