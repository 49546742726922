@import sizes
@import ../functions

$grid-gutter-width: size(12) !default
$grid-cols: 12 !default
$grid-col-width: size(91) !default
$content-external-gutter: $grid-gutter-width
$grid-width: ($grid-col-width + $grid-gutter-width) * ($grid-cols - 1) + $grid-col-width

@function grid-width($i)
  @return ($i * $grid-col-width) + (($i - 1) * $grid-gutter-width)

@function cols($i)
  @return grid-width($i)

@function grid-fraction($i)
  @return ($grid-cols / $i * $grid-col-width) + (($grid-cols / $i - 1) * $grid-gutter-width)

@function grid-padding($cols: 12)
  @return calc((100vw - #{grid-width($cols)}) / 2)

=smaller-than-grid($padding: $grid-gutter-width)
  @media screen and (max-width:  strip-unit(grid-width(12) + $padding * 2) * 16px)
    @content

=larger-than-grid($padding: $grid-gutter-width)
  @media screen and (min-width: strip-unit(grid-width(12) + $padding * 2 - 0.0625) * 16px)
    @content

=grid-width($cols: $grid-cols)
  max-width: grid-width($cols)
