@import var

main.form-page
  padding-bottom: var(--padding-big-size)

  +l
    padding-top: $gutter * 2

    .texts
      padding-right: $gutter * 1.5

    .form-container
      margin-left: 0
      flex: (cols(6) - $gutter / 2) 0 0
      align-self: flex-start
      position: sticky
      top: calc(var(--padding-big-size) * 2)

  .info
    margin-top: $gutter

    +l
      display: flex
      align-items: flex-start

  .insights
    +s
      margin-top: $gutter

main.form-page,
.download-cta
  $width: (cols(2) - $gutter)

  .image
    flex: $width 0 0
    width: $width
    margin-right: $gutter

    img
      width: $width
      height: auto

.download-cta
  padding: var(--padding-big)
  $width: (cols(3) - $gutter)

  a
    margin-top: $gutter / 2

  .image-container
    +flex-center(row)

    +s
      margin-bottom: $gutter
      margin-top: $gutter
      justify-content: flex-start

  .image
    min-height: 0
    flex: $width 0 0
    width: $width

    img
      width: $width

    +small
      flex-basis: 33vw
      width: 33vw

      img
        width: 33vw