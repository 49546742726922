@import ../partials

.visually-hidden
  +visually-hidden

.hidden
  +hidden

@for $i from 1 through 12
  .col-#{$i}
    +grid-width($i)

.content
  position: relative
  +content-width
  margin: 0 auto

  .content
    padding: 0

  &.spaced
    +flex-space

  &.centered
    +flex-center

.bg,
.bg-image
  pointer-events: none
  +full-size-absolute
  z-index: 0

  .image
    +full-size-absolute

.image,
.bg-image
  img, svg, picture
    width: 100%
    height: 100%
    object-fit: cover

.bg-video
  pointer-events: none
  +full-size-absolute
  z-index: 0
  box-sizing: border-box
  overflow: hidden

  video
    object-fit: cover
    width: 100%
    height: 100%

    +browser-edge
      width: 100%
      height: auto

.bg.panoramic
  overflow: hidden

  .bg-image
    width: calc(var(--factor, 2) * 100%)
    animation: pano calc(var(--durations, var(--factor, 2) * var(--base-duration, 120s)) * 1s) ease-out forwards // duration or factor * base-duration
    @keyframes pano
      to
        transform: translateX(-50%)

.horizontal-scroll-container
  +scrollable(true, hidden)
  padding-left: calc(#{grid-padding()} + #{$grid-gutter-width})

  +smaller-than-grid
    padding-left: $grid-gutter-width

  li:last-of-type
    padding-right: calc(#{grid-padding()} + #{$grid-gutter-width})
    box-sizing: content-box

    +smaller-than-grid
      padding-right: $grid-gutter-width

.field
  position: relative
  display: block
  --padding: .25em

  input, textarea, button, select
    background-color: transparent
    padding: var(--padding)
    width: 100%
    border: none
    color: inherit
    margin: 0
    display: block

  select
    margin-top: 2px

  &.select
    &:after
      content: ''
      display: block
      position: absolute
      right: .25em
      top: .25em
      width: .5em
      height: .5em
      border: solid 1px currentColor
      border-top: transparent
      border-left: transparent
      transform: rotate(45deg)

  input, textarea
    &:not(:placeholder-shown),
    &:focus
      ~ span
        //+hidden
        transform: var(--label-transform, translateY(-33%) scale(.67))

    &:required, &[aria-required='true']
      ~ span
        &:after
          content: '*'
          display: inline-block
          margin-left: .15em

  select:valid
    ~ span
      transform: var(--label-transform, translateY(-33%) scale(.67)) //+hidden

  span
    position: absolute
    left: 0
    top: 0
    padding: var(--padding)
    transition: $transition-quick
    pointer-events: none
    transform-origin: var(--label-transform-origin, top left)

.flex-columns-spacer
  flex: 100% 1 0 !important
  height: 0
  +hidden