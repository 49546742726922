@import var

main.careers
  .hero
    +l
      min-height: size(400)

    .text
      font-weight: 500

    a
      margin-top: var(--padding-big-size)

    .logos
      margin-top: $gutter
      justify-content: flex-start

      +s
        flex-direction: row
        justify-content: space-between

    .logo
      flex: 0

      +s
        flex: calc(50% - #{$gutter / 4}) 0 0
        +flex-center

      img
        +l
          height: $gutter-big
          width: auto

  .side-by-side
    .image
      +l
        min-height: size(400)

section.departments
  .card-group
    +l
      +flex-columns(3, $gap: auto)

    a
      &:hover
        color: $saffron

      +l
        display: block
        margin-bottom: $gutter-big

section.stats
  background-color: $tangerine

  &, *
    color: $white

  .title-label
    opacity: .5

  .stats
    +l
      +flex-columns(4, $gap: auto)

    li
      +s
        margin-top: $gutter

        p
          display: flex
          align-items: center

        .title-large
          flex: 2.5em 0 0

        .text
          margin-top: 0

      +l
        padding-right: $gutter-big

    .title-large
      color: $tangerine-30
      display: block
      font-weight: 500

section.vision
  li
    margin-top: $gutter / 2

    p
      margin-top: 0

  .video
    max-width: cols(8)
    margin-top: var(--padding-big-size)

    .video-poster
      flex: 1
      margin: 0

.list-container
  &.full
    background-color: $gray-bg
    margin-top: -$size-256
    padding-top: $size-256

    +l
      padding-bottom: $gutter

.positions-list
  .field
    margin-top: 0

  ul
    margin-top: $gutter * 2

  li
    margin-top: $gutter / 2
    display: flex
    transition: $transition-quick

    +l
      flex-wrap: wrap

    &:not(:first-of-type):not(:nth-of-type(2)):not(.inactive)
      padding-top: $gutter / 2
      border-top: solid size(1) $gray-45

    &.inactive
      +hidden
      height: 0
      margin-top: 0

  p
    margin-top: 0

  a
    +l
      font-weight: 400

      &:not(:hover)
        color: $gray-dark

  .header
    font-weight: 500

    +s
      p
        font-size: size(14)

  .job-title
    flex: 100% 0 0

    +s
      flex-shrink: 1

    +l
      flex: cols(6) 0 0
      padding-right: $gutter

  .locations
    +s
      display: none

    +l
      flex: cols(4) 0 0

  .added
    font-variant-numeric: tabular-nums

    +s
      font-size: size(14)

    +l
      flex: cols(2) 0 0

.social-links
  ul
    +flex-center(row)

  li
    width: size(41)

    &:not(:first-child)
      margin-left: $gutter / 2

  a
    img
      transition: $transition-quick
      opacity: .5

    &:hover
      img
        opacity: 1

section.position
  header
    text-align: center

    a
      margin-top: $gutter

section.impressions
  .impressions-images
    +l
      +flex-columns(3, $gap: auto)

    li
      margin-top: $gutter

    .image
      height: size(250)