@import var

header.site-header
  height: $header-height
  padding: $gutter
  position: relative
  position: sticky
  top: 0
  z-index: 99
  +flex-space
  background-color: $white

  .bg
    box-shadow: $shadow-header
    transition: $transition-mid
    z-index: 0

  &.initial
    .bg
      //+hidden

  +l
    padding: $gutter $gutter * 2

  li
    list-style: none

  a
    text-decoration: none

.site-logo
  width: size(114)
  transform: translateZ(0)
  z-index: 2

  +l
    position: absolute

  .now-maksed path, .period
    transition: $transition-short

  .now-maksed path
    +stagger(3, $duration-quick / 12)
    transform-origin: bottom left

  .site-header:not(.initial) &
    .now-maksed path
      transform: translateX(-80%)
      +stagger(3, $duration-quick / 6, $reverse: true)
      transition-timing-function: $ease-in-circ

    .period
      transform: translateX(-65%)
      transition-delay: $duration-quick * 1.5

.site-nav
  +l
    height: $header-height
    width: 100%
    +flex-center

  +s
    +hidden
    +full-size-fixed
    display: block
    align-items: stretch
    text-align: center
    font-size: 6vmin
    transition: $transition-quick
    background-color: $white
    color: $deep-space
    padding: $header-height 0 $gutter
    overflow: auto

    &:before
      content: ''
      +full-size-fixed($auto: botton)
      height: $header-height
      background-color: $white
      box-shadow: $shadow-header
      z-index: 1

    input:checked ~ &
      +unhidden

    ul
      flex-direction: column
      text-align: initial
      flex-wrap: wrap
      max-height: 100vh
      align-items: flex-start

    a, .main-nav a, li
      padding: $gutter / 2 0

.site-nav-toggle-label
  position: absolute
  z-index: 1
  right: $gutter
  margin: 0
  transition: $transition-short

  span
    position: relative
    width: size(32)
    height: size(32)
    flex-shrink: 0
    +flex-center

  +l
    display: none

  .bars
    display: block
    width: size(24)
    height: size(19)
    position: relative

    span
      position: absolute
      display: block
      background-color: $deep-space
      height: size(3)
      width: 100%
      transition: $transition-quick
      border-radius: size(1.5)

      &:nth-child(1)
        top: 0

      &:nth-child(2),
      &:nth-child(3)
        top: calc(50% - #{size(1.5)})

      &:nth-child(4)
        bottom: 0

  input:checked ~ &
    .bars
      span
        &:nth-child(1),
        &:nth-child(4)
          +hidden
          transform: scaleX(0)

        &:nth-child(2)
          transform: rotate(.125turn)

        &:nth-child(3)
          transform: rotate(-.125turn)

.site-nav-list
  font-size: size(16)

  +l
    +flex-center(row)
    height: 100%

  > li
    color: $deep-space

    > span,
    > a,
    > label
      font-weight: 600

    > label
      cursor: default

      +s
        font-size: size(25)
        display: flex
        align-items: center

        &:after
          content: ''
          background-image: url("data:image/svg+xml,%3Csvg width='6px' height='2px' viewBox='0 -1 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline id='arrow-small-deep-space' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke='%231C1E39' points='6 0 3 2 0 0'%3E%3C/polyline%3E%3C/svg%3E")
          width: size(14)
          height: size(6)
          margin-top: size(4)
          margin-left: -$gutter / 2
          transition: $transition-quick

    +l
      height: 100%
      +flex-center

      &:not(.contact)
        padding: size(4) $gutter

        &:after
          content: ''
          +full-size-fixed
          top: $header-height
          background-color: $black
          opacity: .5
          z-index: -1
          pointer-events: none
          transition: none

        &:not(:hover):after
          +hidden
          transition: none

        &:hover
          background-image: linear-gradient(to top, $deep-space, $deep-space size(4), transparent size(4))
          background-size: calc(100% - #{$gutter * 2})

      > span
        cursor: default

      &.contact
        position: absolute
        right: $gutter * 3.5
        margin: 0
        z-index: 1
        transition: $transition-short

        .site-header:not(.initial) &
          transform: translateX($size-48)
          transition-timing-function: $ease-in-sine

  .contact
    a
      @extend .full

    +s
      padding: $gutter / 2 $padding-s $gutter

      a
        padding: $gutter / 2
        display: block
        text-align: center


.menu-item-title
  +s
    transition: $transition-quick

    label &
      padding-right: $padding-s
      padding-left: $padding-s

    input:checked ~ label &
      color: $tangerine

input:checked ~ label
  +s
    &:after
      background-image: url("data:image/svg+xml,%3Csvg width='6px' height='2px' viewBox='0 -1 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline id='arrow-small-deep-space' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke='%23FF6B40' points='6 0 3 2 0 0'%3E%3C/polyline%3E%3C/svg%3E")

.main-menu-container
  +s
    transition: $transition-mid
    overflow: hidden
    +scroll-height-auto
    padding-right: $padding-s
    padding-left: $padding-s
    margin-bottom: $gutter * 2

    input:not(:checked) ~ &
      height: 0
      margin-bottom: 0

  +l
    +full-size-absolute(0, bottom)
    top: $header-height
    background-color: $white
    transition: none
    box-shadow: $shadow-header-inset

    // .site-nav-list > li:not(:hover) &:not(:hover)
    input:not(:checked) ~ &
      +hidden
      transition: none

.main-menu
  +l
    +content-width
    margin: 0 auto
    display: flex
    justify-content: space-between

  > li
    &.empty
      > span
        display: none

      > .sub-menu-container
        margin-top: -$gutter / 2

    +l
      padding: var(--padding-big-size) var(--padding-big-size)
      flex: 1

    .sub-menu-container
      li
        +l
          margin-top: $gutter / 2
          margin-bottom: $gutter

    &:first-child
      padding-left: 0

      .sub-menu-container
        ul
          +l
            flex-wrap: wrap
            display: flex

            li
              flex: (cols(4) - $gutter * 2) 0 0
              padding-right: $gutter * 2

    &:last-child:not(:first-child)
      position: relative

      +s
        padding: $gutter / 2
        margin-left: -$gutter / 2
        margin-right: -$gutter / 2

      +l
        flex: cols(4) 0 0

      &:after
        content: ''
        +full-size-absolute
        background-color: $gray-bg
        mix-blend-mode: multiply
        pointer-events: none

        +l
          right: calc(((100vw - #{grid-width(12)}) / -2) - #{$gutter})

          //right: calc(((100vw - #{grid-width(12)}) / -2))

          //right: auto
          //width: calc(100% + ((100vw - 72rem) / 2) + 7.4px) // wierd windows bug
          //background-color: transparent
          //background-image: linear-gradient(to right, $gray-bg 67%, transparent)

      a
        font-size: size(16)

      li
        margin-bottom: 0
        margin-top: $gutter / 2.5

        +s
          padding: 0

      .sub
        margin-top: $gutter

        a:not(:hover), span span
          color: $deep-space-45
          font-weight: 500

    a
      font-size: size(22)

  .text-small
    color: $gray-dark
    margin-top: size(6)
    line-height: 1.2

.main-menu > li
  > span,
  > a:first-child:last-of-type .menu-item-title
    @extend .title-label, .small
    font-size: size(14)
    display: block
    margin-bottom: 0

  > a:first-child:last-of-type .menu-item-title
    transition: $transition-quick

    &:hover
      color: $tangerine

.language-nav
  font:
    size: size(10)
    weight: 600
  color: $deep-space
  text-transform: uppercase
  letter-spacing: .12em
  padding: $gutter / 2
  display: flex
  transition: $transition-quick
  position: absolute
  justify-content: flex-end
  right: $gutter

  +l
    flex-direction: column

  +s
    right: $gutter * 2
    font-size: size(12)

    label, li
      transition-delay: calc(var(--i, 0) * #{$duration-quick / 2})

    #site-nav-toggle:not(:checked) ~ &
      label, li
        +hidden
        transition-delay: calc(2 - var(--i, 0) * #{$duration-quick / 2})
        //transition-delay: 0s

  label
    transition: $transition-quick
    position: relative
    display: flex
    align-items: center

    +l
      &:after
        content: ''
        width: size(10)
        height: size(4)
        right: size(-10)
        margin-top: size(2)
        position: absolute
        display: inline-block
        transition: inherit
        background-size: size(12)
        background-position-x: size(1)
        background-image: url("data:image/svg+xml,%3Csvg width='6px' height='2px' viewBox='0 -1 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline id='arrow-small-deep-space' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke='%231C1E39' points='6 0 3 2 0 0'%3E%3C/polyline%3E%3C/svg%3E")

      &:hover
        color: $tangerine

        &:after
          background-image: url("data:image/svg+xml,%3Csvg width='6px' height='2px' viewBox='0 -1 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline id='arrow-small-deep-space' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke='%23FF6B40' points='6 0 3 2 0 0'%3E%3C/polyline%3E%3C/svg%3E")

  a
    font-weight: 500

  +l
    right: $gutter * 1.5

    .site-header:not(.initial) &
      transform: translateX($size-12)
      +hidden

  ul
    transition: $transition-quick
    +l
      height: auto
      height: calc(var(--scroll-height, 24px) - 1em)

    +s
      display: flex
      align-items: center

  li
    transition: $transition-short
    +stagger(2, $duration-quick / 2)
    transform: translateZ(0)

    +s
      margin-left: $gutter / 2

  +l
    #language-nav-toggle:not(:checked) ~ &
      ul
        height: 0

      li
        +hidden
        transform: translateY(67%) translateZ(0)
        transition: opacity $duration-quick / 2 $ease, transform 0s $duration-quick