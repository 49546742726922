@import var

section.graphs
  background-color: $deep-space

  &, *
    color: $white

  +l
    .content
      +flex-columns($gap: auto)

  .super-underline
    @extend .underline-tangerine

  .graphs
    +l
      display: flex
      justify-content: flex-end

  .graph
    +flex-center

    +l
      margin-left: $gutter

  svg:not(.super-underline-svg)
    width: size(224)
    height: size(224)

  circle
    fill: none
    stroke: currentColor

  .under
    opacity: .5

  .over
    stroke-width: size(3)
    stroke-linecap: round
    stroke-dasharray: 100
    stroke-dashoffset: 100
    transition: $transition-long $duration-mid

  .percentage
    font-weight: 600
    position: absolute
    margin-top: -.5em
    transition: $transition-main $duration-long
    +hidden

  .in-view
    .over
      stroke-dashoffset: var(--percentage-reverse) // why can't FF do calc(--var) ???

    .percentage
      +unhidden