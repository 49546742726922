@import var

section.milestones
  ul
    +l
      +scroll-height-auto
      margin-top: $gutter-big
      position: relative

      &:before
        content: ''
        +full-size-absolute($auto: right)
        width: size(1)
        background-color: $deep-space-30

  li
    margin-bottom: $gutter
    border-top: solid size(1) transparentize($white, .75)

    +l
      width: cols(5)
      padding-left: $gutter * 2

  .texts
    transition: $transition-quick
    margin-top: $gutter
    +scroll-height-auto

    +l
      position: absolute
      top: 0
      width: cols(6)
      right: $padding-s
      margin-top: 0

      > *
        transform: translateZ(0)

    .text-large
      color: $deep-space
      font-weight: 600
      margin-bottom: .6em

  h3
    font-weight: 600
    transition: $transition-quick
    margin-left: 0

    +l
      display: flex
      align-items: center

      &:before
        content: ''
        display: block
        position: absolute
        left: size(-14/2)
        +circle(size(14))
        background-color: $deep-space
        transition: $transition-quick

  input:not(:checked) ~ .texts
    +hidden

    +s
      height: 0

    .super-underline
      &:after
        --url: none

  input:checked ~ .texts
    +l
      animation: history-in $duration-mid $ease 1 backwards
      @keyframes history-in
        from
          opacity: 0
          transform: translateY($gutter / 2)

  input:not(:checked) ~ h3
    opacity: .25

    &:hover
      opacity: .5

    &:before
      +hidden