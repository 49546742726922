@import var

html
  --padding-big-size: #{$padding-s}
  --padding-big: var(--padding-big-size) 0
  overflow-y: auto
  overflow-x: hidden

  +l
    --padding-big-size: #{$gutter-big}
    --padding-big: var(--padding-big-size) 0

::selection
  background-color: $tangerine
  color: white

main
  //overflow: hidden
  padding-top: var(--padding-big-size)
  min-height: 50vh

section
  margin-top: var(--padding-big-size)

  &.full
    background-color: $gray-bg
    padding: var(--padding-big)

    + .full,
    .content &
      margin-top: 0

    .post-content &
      margin: var(--padding-big-size) 0
      padding: var(--padding-big-size)

section:not(.post-content)
  a
    text-decoration: none

  li
    list-style: none

.post-content,
section:not(.post-content) .text,
.text
  ul, ol
    margin-left: 1.25em

  li
    margin-top: .6em

  a:not([class])
    text-decoration: underline
    font-weight: inherit

    &:not(:hover)
      color: inherit

  strong
    color: $deep-space

  h2:not([class])
    @extend .title-mid

  h3:not([class])
    @extend .title-small

  h4, h5, h6
    &:not([class])
      @extend .text-large

  h1,h2,h3,h4,h5,h6
    &:not([class])
      margin-top: .66em

  iframe
    width: 100%
    aspect-ratio: 16/9

    +l
      width: cols(12)
      height: cols(12) * 9 / 16

label
  cursor: pointer

.center-container
  +flex-center

a.full
  padding: .5em 1.5em
  background-color: $tangerine
  color: $white
  font-weight: 500
  transition: $transition-quick

  &:hover
    background-color: lighten($tangerine, 5%)

em.super-underline
  font-style: normal
  position: relative
  z-index: 1
  display: inline-block

  +l
    white-space: pre

  svg
    display: inline
    position: absolute
    bottom: -.275em
    left: -.2em
    right: -.2em
    height: .6em
    z-index: -1
    width: calc(100% + 0.2em)

    +l
      min-width: 2em

  @keyframes stroke-dashoffset-in
    to
      stroke-dashoffset: 0

  &:not(.static)
    .super-underline-mask
      path
        stroke-dasharray: 1
        stroke-dashoffset: 1

  .in-view &,
  &.in-view
    .super-underline-mask
      path
        animation: stroke-dashoffset-in $duration-main ease-in 1 forwards

  [data-in-view-class]:not(.in-view) &.in-view,
  .adjective:not(.in-view) &.in-view
    .super-underline-mask
      path
        animation: none
        stroke-dashoffset: 1

  &.underline-tangerine
    --underline-color: #{$tangerine}

.underlined, .arrow
  text-decoration: none
  font-size: size(22)
  line-height: 1
  display: inline-block
  position: relative
  padding-bottom: size(4)

  +s
    font-size: size(17)

  &.small
    font:
      size: size(18)
      weight: 600

    +s
      font-size: size(16)

.underlined
  font-weight: 600

  +s
    &, section:not(.post-content) &
      text-decoration: underline

  .texts &
    margin-top: $gutter

  +l
    span
      transition: inherit
      background-image: linear-gradient(to right, transparent, transparent 50%, currentColor 50%, currentColor)
      background-position: 100% 100%
      background-size: 200% size(2)
      line-height: 1.25

  +hover
    span
      background-size: 0 size(2)

  &.white
    color: $white

.arrow,
.bordered span
  &:after
    content: ''
    display: inline-block
    margin-left: .5em
    width: size(14)
    height: size(12)
    transition: $transition-quick
    top: calc(.525em - #{size(6)})
    background-image: url('../media/arrow-static.svg')

.underlined
  &:after
    content: ''
    display: inline-block
    margin-left: .5em
    width: size(14)
    height: size(12)
    top: calc(.525em - #{size(6)})
    background-image: url('../media/arrow-tangerine-static.svg')
    opacity: 0
    transform: translateX(-.25em)

    +l
      transition: $transition-quick, transform 0s $duration-quick

  +hover
    &:after
      transition: $transition-quick
      +unhidden

  &.white
    color: $white

    &:after
      background-image: url('../media/arrow-white-static.svg')

.arrow
  margin-top: $gutter / 2

  +hover
    &:after
      transform: translateX(.25em)
      background-image: url('../media/arrow-tangerine-static.svg')

  &.white
    color: $white

    &:after
      background-image: url('../media/arrow-white-static.svg')
      animation: none

    +hover
      color: $white

      &:after
        background-image: url('../media/arrow-white-static.svg')

  &.back
    margin-left: $gutter

    &:after
      position: absolute
      left: size(-14) - $gutter
      transform: scaleX(-1)

    +hover
      &:after
        transform: translateX(-.25em) scaleX(-1)

.download
  $width: size(21)
  position: relative
  align-items: baseline
  display: inline-flex
  font-weight: 600
  max-width: calc(100% - #{$width})
  text-decoration: none

  &:before, &:after
    content: ''
    position: absolute
    right: calc(-.75em - #{$width / 2})
    bottom: 0 //.15em
    height: 1em
    width: $width
    background-size: $width 1em
    transition: $transition-quick

  &:before
    background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='22' x2='20' y2='22' fill='none' vector-effect='non-scaling-stroke' stroke='%23121936' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3C/svg%3E")

  &:after
    background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline vector-effect='non-scaling-stroke' stroke='%23121936' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' points='18 12.9368936 10.5 19 3 12.9368936 10.5 19 10.5 1'%3E%3C/polyline%3E%3C/svg%3E")
    transform: translateY(size(-5))

  &.white
    color: $white

    &:before
      background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='22' x2='20' y2='22' fill='none' vector-effect='non-scaling-stroke' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3C/svg%3E")

    &:after
      background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline vector-effect='non-scaling-stroke' stroke='%23FFFFFF' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' points='18 12.9368936 10.5 19 3 12.9368936 10.5 19 10.5 1'%3E%3C/polyline%3E%3C/svg%3E")

  +hover
    &:before
      background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='22' x2='20' y2='22' fill='none' vector-effect='non-scaling-stroke' stroke='%23FF6B40' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3C/svg%3E")

    &:after
      transform: translateY(size(-2))
      background-image: url("data:image/svg+xml,%3Csvg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline vector-effect='non-scaling-stroke' stroke='%23FF6B40' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' points='18 12.9368936 10.5 19 3 12.9368936 10.5 19 10.5 1'%3E%3C/polyline%3E%3C/svg%3E")

.bordered
  display: inline-block
  border: solid size(1) currentColor
  padding: .6em calc((1em + #{size(14)})) .7em
  text-decoration: none
  font:
    size: size(20)
    weight: 600
  background:
    image: linear-gradient($tangerine, $tangerine)
    size: 100% 0
    position-y: 100%

  +l
    transition: $transition-quick

  +s
    padding: .6em .6em .7em
    text-align: center
    background-color: $tangerine
    color: $white

  .post-content &, .text &
    margin-top: $gutter / 2

  span
    position: relative
    display: block
    backface-visibility: hidden

    +l
      transition: inherit

    &:after
      position: absolute
      animation: none
      right: -$gutter
      top: calc(50% - #{size(12/4)})
      background-image: url('../media/arrow-white-static.svg')
      opacity: 0
      transform: translateX(-$gutter / 2) translateZ(0)
      transition: none

      +s
        display: none

  +hover
    color: $white
    border-color: $tangerine
    background-size: 100% 100%
    //--url: url('../media/svg.php?url=arrow-white.svg')

    span
      transform: translateX(size(-14/1.5))
      transition: $transition-quick //, color $duration-short $ease $duration-quick

      &:after
        transition: all $duration-short $ease $duration-quick / 2
        +unhidden

.quote
  position: relative

  &:before
    content: ''
    width: $size-72
    height: $size-72
    position: absolute
    top: size(-42)
    left: size(-48)
    background-image: url('../media/quote.svg')
    pointer-events: none

    +s
      width: $size-48
      height: $size-48
      top: -$size-32
      //top: -$size-72 - $gutter
      left: -$size-12

  &, p
    font-weight: 500
    color: $deep-space
    line-height: 1.25

    * + *
      margin-top: .66em

  ~ .name
    display: block
    font-size: size(16)
    margin-top: $gutter

.video-container
  .content
    min-height: size(400)

  a
    margin-top: $gutter

.video-poster
  +flex-center
  min-height: size(400)
  position: relative

  svg
    width: $size-96

  a
    +flex-center
    +full-size-absolute
    transition: $transition-quick
    backface-visibility: hidden

    &:hover
      transform: scale(.95)
      opacity: .75

.card
  background: $white
  box-shadow: $shadow-card
  border-bottom: solid size(5) var(--color, #{$deep-space})
  padding: $gutter
  +color-classes($properties: ('border-color', '--color'))
  color: $deep-space
  display: flex
  flex-direction: column

  &.top
    border-bottom: none
    border-top: solid size(5) var(--color, #{$deep-space})

  &.flat
    background-color: $gray-bg
    box-shadow: none

  ul, ol
    &.links
      margin-left: 0

      +s
        word-break: break-word
        hyphens: auto

  .texts, .content
    display: flex
    flex-direction: column
    align-self: stretch

  .title-small
    font-weight: 600

  header
    ul, ol
      margin-top: $gutter / 2
      margin-left: 1.25em

    li
      list-style: disc outside
      margin-top: $gutter / 2
      font-weight: 400

  footer
    padding-top: var(--padding-big-size)
    margin-top: auto

  .icon
    height: $size-64
    width: $size-64
    +flex-center
    margin-bottom: $gutter / 2

  .links
    a
      margin-top: $gutter / 2

    .arrow
      display: flex
      align-items: center
      justify-content: space-between
      padding-right: $gutter

      &:after
        position: absolute
        right: 0
        top: calc(50% - #{size(12) / 2})

.cards-group
  margin-top: 0
  margin-bottom: var(--padding-big-size)

  section + &
    margin-top: var(--padding-big-size)

  .card.full
    flex: 100% 1 0
    margin-right: 0
    margin-left: 0

  .link
    margin-top: $gutter
    display: flex
    justify-content: flex-end

.card-group
  +l
    +flex-columns($gap: auto)

    &.three
      +flex-columns(3, $gap: auto)

  .card
    margin-top: $gutter

.feature-card
  padding: size(15)
  width: size(248)
  font-weight: 500

  +s
    margin: auto

  p:not(.text-small)
    font-size: size(16)
    line-height: 1.25
    letter-spacing: -.025em

    +de
      font-size: size(14)

  .content
    flex-direction: row
    align-items: center
    margin: $gutter / 2 0 size(15)

  .icon
    flex: 0 0 $size-64
    margin-right: $gutter / 2
    width: $size-64
    height: $size-64

    +s
      width: $gutter * 2
      height: $gutter * 1.5

    img, svg
      height: 100%
      width: 100%
      object-fit: contain
      object-position: left

  .text-small
    line-height: 1
    text-transform: none
    color: var(--color)

.card-container
  transition: $transition-mid //, transform $duration-mid $ease-out-sine $duration-quick
  transform: translateZ(0)
  position: absolute

  +s
    width: 100%

  +l
    &:nth-child(odd)
      bottom: $gutter
      right: -$gutter

    &:nth-child(even)
      bottom: size(104)
      left: -$gutter-big + size(6)

    &:not([data-active-in='0']):not([data-active-in='1'])
      +hidden
      z-index: 0
      transition: $transition-mid, transform $duration-long * 2 ease-in

      &[data-active-ago='1'], &[data-active-ago='2']
        transition: $transition-main
        transform: scale(1.3)

      &[data-active-in='2']
        transform: scale(.8)

  +s
    &:not([data-active-in='0'])
      +hidden
      z-index: 0
      transition: $transition-mid, transform $duration-long * 2 ease-in

    &[data-active-ago='1']
      transition: $transition-main
      transform: scale(1.2)

    &[data-active-in='1']
      transform: scale(.875)

.feature-cards
  +s
    position: relative
    +flex-center
    width: 100%
    margin-top: -$gutter-big
    margin-bottom: $gutter
    height: var(--scroll-height, 100px)

.updates
  +s
    margin: $gutter * 2 0

.update
  margin-top: $gutter
  font-weight: 500

  &.with-image
    align-items: center

  +l
    padding: $size-32
    flex-direction: row

  .subtitle
    color: $gray-dark
    font-weight: 400
    margin-top: .33em

  .title-small, a
    font-weight: 600

  .image
    +s
      margin-top: $gutter
      width: 100%
      height: size(160)

    +l
      flex: 40% 0 0
      height: size(260)
      margin-left: $gutter / 2

  img
    object-fit: contain

    +l
      object-position: right

ul.icons
  +s
    margin-top: $gutter

  li
    display: flex
    margin-bottom: $gutter

  .icon
    flex: size(28) 0 0
    margin-right: $gutter / 2

.hero
  +l
    margin-top: calc(var(--padding-big-size) * -1)
    padding-top: var(--padding-big-size)

    > .content
      +flex-columns($gap: auto)

      &:nth-child(2)
        align-items: flex-end

  .title-label
    color: $deep-space

  +de
    +l
      .title-mid
        font-size: size(40)

.trusted-by
  margin-top: var(--padding-big-size)

  &.wide
    .text-image-block > .content &
      +l
        flex: 100% 0 0
        margin-top: $gutter-big * 2

        ul
          justify-content: space-between

  +s
    text-align: center

  .hero .content:nth-child(2) &
    &:not(:first-child)
      margin-left: auto
      flex-basis: auto

      +l
        flex: auto 0 0
        min-width: calc(var(--width) - #{$gutter})
        width: auto

  .title-label.small
    color: $deep-space-15
    font-weight: 500
    margin-bottom: 0

  img
    margin-top: $gutter / 2
    margin-right: $gutter * 3 / 4
    height: 63px
    width: auto

    +s
      margin-left: $gutter / 2
      margin-top: size(4)

  .logos
    display: flex
    flex-wrap: wrap

    +s
      justify-content: center

    &.large
      +l
        img
          margin-right: $gutter
          height: $size-84

    &.dim
      opacity: .5

.graphic
  +flex-center
  position: relative

  +l
    flex: 1

.columns-2
  +l
    +flex-columns($gap: auto)

i.icon
  display: block

.product-ticket
  background-color: $tangerine
  padding: $gutter
  display: flex
  width: cols(3) - $gutter

  &, &:hover
    color: $white

  .ticket-texts
    margin-left: $gutter / 2

  .icon
    flex: $gutter * 2 0 0
    width: $gutter * 2

  p
    line-height: 1

  .text-large
    font-weight: 600

.book-mockup
  +shadow(4, $h: -1.4, $v: 1.4, $blur-factor: 3)

  &.large-shadow
    +shadow(5, $h: -1.2, $v: 1.2, $blur-factor: 2)

section.bg-deep-space,
section.bg-tangerine
  color: $white

  h2,h3,h4,h5,h6,
  [class^=title],
  .text-large,
  a:not(:hover)
    color: inherit

section.bg-deep-space
  background-color: $deep-space

section.bg-tangerine
  background-color: $tangerine

section.bg-white
  background-color: $white

@for $i from 2 through 12
  .max-#{$i}
    max-width: cols($i)

.areas
  margin-top: var(--padding-big-size)

.text-image-block
  > .content
    +l
      +flex-columns($gap: auto)
      align-items: center
      justify-content: space-between

    +s
      display: flex
      flex-direction: column-reverse

  .graphic
    +l
      +flex-center
      align-self: stretch
      max-width: cols(5)

      > .image,
      > .card
        position: absolute

      > .image
        +full-size

    +s
      .image
        width: 100%

  .card
    bottom: 0

  .image
    background-size: contain
    min-height: $size-256

    &.video-poster
      background-size: cover

    +l
      background-position-x: right
      min-height: size(412)

  &.reverse
    > .content
      +l
        +flex-columns($gap: auto, $reverse: true)

        .image
          background-position-x: left

  &.top
    > .content
      +l
        align-items: flex-start

  &.image-hug
    +l
      .hero &
        .texts
          flex-basis: cols(7)

      .image
        position: absolute
        right: calc(-1 * #{grid-padding()})
        top: 0
        width: calc(#{cols(4)} + #{grid-padding()} - #{$gutter})
        max-width: cols(7)
        bottom: 0
        z-index: 1

        &.oversize
          top: calc(-1 * var(--padding-big-size) + #{$gutter})
          bottom: calc(-1 * var(--padding-big-size) - #{$gutter})

        &.cover
          background-size: cover

    &.reverse
      +l
        .image
          right: auto
          left: calc(-1 * #{grid-padding()})

    +s
      .content
        flex-direction: column

        .image
          margin-top: $gutter

.title-text-block
  .content
    +l
      +flex-columns($gap: auto)

      .text
        flex: 1

        p:first-child
          margin-top: 0

  +s
    .content, .text
      margin-top: $gutter

.text-columns-block
  margin-top: calc(var(--padding-big-size) * 2)

  +l
    *[class^=title]
      max-width: cols(6)

    .text
      margin-top: $gutter
      columns: 2
      column-gap: $gutter

      p
        break-inside: avoid

        &:first-child
          margin-top: 0

.better
  &.standard
    color: $deep-space
    margin-bottom: var(--padding-big-size)

    .texts
      +l
        +flex-columns($gap: auto)
        align-items: flex-start

    .better-item
      background-color: $gray-bg
      padding: $gutter-big

      &:nth-child(even)
        margin-top: var(--padding-big-size)

      +l
        &:after
          content: ''
          position: absolute
          height: size(141)
          width: size(216)
          top: size(10)
          right: size(-63)
          z-index: -1
          background-image: url('../media/dots-deep-space.png')

      +s
        margin: -$padding-s / 2
        padding: $gutter / 2

    li
      display: flex
      align-items: baseline
      margin-top: $gutter / 2
      font-weight: 500

      &:before
        content: ''
        width: size(15)
        height: size(15)
        margin-right: $gutter / 2
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='15px' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape Copy 4%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1,9.54923599 L4.35790403,14 L8.37453834,8.58455442 M10.8870125,5.19709959 L14,1' id='Shape-Copy-4' stroke='%231C1E39' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")

  &.text-image-block
    padding: var(--padding-big-size) 0

    .title-large
      @extend .title-mid

    .texts
      margin-bottom: $padding-s

      +l
        margin-bottom: $gutter * 2

    .better-item
      margin-top: $padding-s

      +l
        margin-top: $gutter * 2

.latest-news
  .content
    display: flex
    flex-direction: column

  .arrow
    margin-top: $gutter

    +l
      align-self: flex-end
  
  .news-item .image
    height: unset

    > img
      object-fit: unset
      height: unset

    +medium
      position: relative
      height: 400px
      overflow: hidden

      > img
        position: absolute
        object-fit: cover
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
    
    +l
      height: auto
      padding-top: 52.25% // 1.91 aspect ratio (1200x630, like by LinkedIN and facebook)
          

.news-items
  +l
    +flex-columns(4, $gap: auto)

.news-item
  display: flex
  flex-direction: column-reverse
  justify-content: flex-end
  margin-bottom: $gutter
  hyphens: auto
  word-wrap: break-word
  max-width: var(--width)

  .text-large
    margin-top: $gutter / 2
    line-height: 1.05
    font-size: size(22)

  .tag
    margin-top: $gutter
    font-size: size(16)

  .image
    height: cols(3) - $gutter / 2
    //background-color: $gray-bg

    img
      object-fit: contain
      object-position: left

.lets-talk
  +s
    background-color: $deep-space
    margin-top: $gutter-big

  .texts
    background-color: $deep-space
    width: 100%
    max-width: cols(10)
    padding: var(--padding-big-size) var(--padding-big-size)

    +s
      padding: $gutter * 2 0 $gutter

  .title-large
    font-size: $size-64
    color: $aqua

    +small
      font-size: 13vw

  a
    margin-top: $gutter

    +small
      font-size: 6vw

  .graphic
    position: absolute
    left: cols(10) - size(69)
    bottom: $gutter-big
    width: size(284)
    height: size(44)

    +s
      bottom: auto
      left: auto
      top: size(-22)
      right: $gutter / 2
      width: 55vw
      z-index: 2

      #pill
        transform: translateX($gutter)

  &.in-view
    +l
      a
        animation: lets-talk-in $duration-long $ease $duration-quick backwards
        @keyframes lets-talk-in
          from
            transform: translateY(.5em)
            opacity: 0

.sales-form
  > .content
    +l
      display: flex

      .texts
        flex: cols(4) 0 0
        margin-right: cols(1)

  &:not(.bg-deep-space):not(.bg-white)
    margin-bottom: var(--padding-big-size)

main.legal
  padding-bottom: $gutter

  h1
    @extend .title-large

h3[style*='clear:both'] // fix for glossary plugin
  &:not([class])
    padding-top: $gutter * 2
    margin-left: -10px

section.ch2 // fix for CookieHub section
  margin-top: 0