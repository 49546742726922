@import var

main.industry
  .hero
    overflow: hidden

    .content:nth-child(2)
      margin-top: var(--padding-big-size)

    +l
      .texts
        flex-basis: cols(6) + $gutter

      .graphic
        flex-basis: cols(6) - $gutter * 3

      .content:nth-child(2)
        justify-content: space-between

      .link
        flex-shrink: 1
        flex-basis: auto

    +s
      .graphic
        display: block
        //+flex-center
        padding: $gutter $gutter-big

      .link
        +flex-center

      .trusted-by
        margin-top: $gutter * 2

.use-case
  + .use-case
    margin-top: $gutter-big

  .link
    display: block

  .graphic
    height: size(400)

    +s
      display: none

  .image
    +l
      min-height: 0
      background-position-x: center

    +s
      margin-bottom: $gutter