@import var

main.brand
  //.title-label
  //  color: $deep-space

  .hero
    .video
      +s
        margin-bottom: $gutter-big

  .quote
    margin-top: $gutter-big

    +l
      display: grid
      gap: $gutter-big
      grid-template-columns: calc(50% - #{$gutter-big}) 50%
      grid-template-rows: repeat(2, auto)

      .text
        &:not(:first-child)
          grid-column: 2
          grid-row: 1 / -1
          margin-top: 0
          margin-left: $gutter / 2

    p
      font-weight: 400
      color: $gray-dark

      &:before
        content: none

  .quotee
    display: flex
    align-items: center
    margin-top: $gutter
    grid-row: 2
    grid-column: 1

    .image
      +circle($gutter-big * 2)
      margin-right: $gutter

      +s
        +circle($gutter-big * 1.5)

    .name
      font-weight: 600

    .signature
      margin: .5em 0 .5em -1em
      pointer-events: none

  .statements
    +s
      margin-top: $gutter-big

    .text-image-block
      .content
        +l
          min-height: size(400)
          align-items: center

        +s
          .title-label
            margin-top: $gutter

          .graphic
            display: none

    strong
      @extend .text-large

  .visuals
    +s
      section
        margin-top: $gutter-big

      .title-label
        margin-top: $gutter

  .video-vision
    padding: var(--padding-big)

    .bg
      background-color: $gray-bg
      margin-bottom: $size-256

    .title-large
      letter-spacing: -.015em

    .text-large
      font-weight: 500
      color: $deep-space

    .video
      max-width: cols(9)
      margin-top: var(--padding-big-size)

    .video-poster
      flex: 1
      margin: 0