@import var

main.partner
  .trusted-by
    +l
      padding-top: $gutter

  .tailored
    h3, .icon
      height: $gutter * 1.5

    .icon
      flex-basis: size(36)

    .text
      +s
        margin-top: 0

    .icon
      +flex-center