@import var

main.press-room
  .hero
    .text-large
      font-weight: 500

    .content
      &:first-child
        padding-bottom: var(--padding-big-size)

    .card
      p
        font-weight: 400
        color: $gray-dark

      a
        margin-top: $gutter

        @media (max-width: 418px) and (min-width: 395px)
          min-width: 322px // dirty dirty hack to solve arrow wrapping on specific devices

    .internal-nav
      margin-top: $gutter

      +l
        display: flex
        align-items: flex-end

      a
        flex: 50% 0 0
        margin-top: $gutter

        +s
          display: block

  .news-items
    +l
      +flex-columns(4, $gap: auto)

      .news-item
        margin-bottom: $gutter * 2

section.assets
  .content
    +l
      padding-bottom: $gutter

  .title-label
    opacity: .5

  .links
    +s
      display: flex
      flex-direction: column

    +l
      +flex-space
      flex-wrap: wrap

    a
      margin-top: $gutter

section.distribution
  padding: var(--padding-big-size) 0


.latest-press
  .posts-links
    padding-bottom: var(--padding-big-size)
    +flex-space

  .arrow
    padding-right: 0

    a
      font:
        size: size(18)
        weight: 600

    &.back
      &:after
        top: .15em

  .newer-posts
    margin-left: auto