@import var

.side-by-side
  margin-top: var(--padding-big-size)

  .text, header
    > *:first-child
      margin-top: 0

  header
    &.thin
      +l
        max-width: cols(3)
        margin-right: cols(1) - $gutter / 2

  .image
    max-width: cols(5)

    +s
      margin-bottom: $gutter

  &.cover
    .content
      align-items: stretch

    .image
      background-size: cover

      +l
        min-height: 0

  &.contain
    .image
      background-size: contain

  a
    margin-top: $gutter