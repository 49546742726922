=full-size($inset: 0, $auto: false, $null: false)
  $sides: top, bottom, left, right
  @each $side in $sides
    // if side isn't in $null
    @if not (index($null, $side))
      // set to auto is side is in $auto, else set to $inset
      #{$side}: if(index($auto, $side), auto, $inset)

=full-size-absolute($inset: 0, $auto: false, $null: false)
  +full-size($inset, $auto, $null)
  position: absolute

=full-size-fixed($inset: 0, $auto: false, $null: false)
  +full-size($inset, $auto, $null)
  position: fixed

=full-size-sticky($inset: 0, $auto: false, $null: bottom)
  +full-size($inset, $auto, $null)
  position: sticky

=flex-center($direction: column, $items: center)
  display: flex
  flex-direction: $direction
  justify-content: center
  align-items: $items

=flex-space($direction: row, $items: center)
  display: flex
  flex-direction: $direction
  justify-content: space-between
  align-items: $items

=flex-columns($cols: 2, $gap: false, $reverse: false, $wrap: true)
  display: flex

  @if ($wrap)
    flex-wrap: wrap

  @if ($reverse)
    flex-direction: row-reverse

  @if ($gap == auto and $grid-gutter-width)
    $gap: $grid-gutter-width

  > *
    @if ($gap)
      --width: calc(#{(100 / $cols * 1%)} - #{($gap / $cols * ($cols - 1))})
      flex: var(--width) 0 0
      margin-left: $gap / 2
      margin-right: $gap / 2

      @if ($reverse)
        &:nth-child(#{$cols}n - #{$cols - 1})
          margin-right: 0

        &:nth-child(#{$cols}n)
          margin-left: 0

      @else
        &:nth-child(#{$cols}n - #{$cols - 1})
          margin-left: 0

        &:nth-child(#{$cols}n)
          margin-right: 0

    @else
      --width: calc(100 / #{$cols} * 1%)
      flex: var(--width) 0 0
