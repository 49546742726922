@import ../partials/mixins

html
  box-sizing: border-box
  font-size: 16px

  +huge
    font-size: 20px

*, *:before, *:after
  box-sizing: inherit
  background:
    size: cover
    position: center
    repeat: no-repeat

body
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

ul, ol, figure
  margin: 0
  padding: 0

h1,h2,h3,h4,h5,h6
  line-height: 1
  margin: 0
  font-weight: inherit
  font-size: 1em

p, q, blockquote
  margin: 0

  &:empty
    display: none

input, textarea, button, select
  font-family: inherit
  font-size: inherit
  color: inherit
  letter-spacing: inherit
  text-align: inherit
  resize: none

  &:not([type=checkbox]):not([type=radio])
    -webkit-appearance: none

  &:focus:not(:focus-visible)
    outline: none

label
  cursor: pointer

select option
  color: initial

a
  color: inherit

button
  cursor: pointer
  border: none
  margin: 0
  padding: 0
  width: auto
  overflow: visible
  background: transparent
  font: inherit
  display: block
  line-height: normal
  -webkit-font-smoothing: inherit
  -moz-osx-font-smoothing: inherit
  -webkit-appearance: none

video
  width: 100%

section,
article,
main,
header,
footer
  position: relative

img, svg, picture
  width: 100%
  height: auto
  display: block

blockquote
  quotes: '"' '"' '"' '"'

  p
    &:before
      content: open-quote

    &:last-of-type:after
      content: close-quote