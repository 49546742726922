@import var

$left: cols(1) + $gutter
section.user-flow
  background-color: $deep-space

  &, *
    color: $white

  +l
    .content
      +flex-columns($gap: auto)

  .super-underline
    @extend .underline-tangerine

  .title-label
    opacity: .5

  .steps-title
    font-weight: 600

  .steps-container
    padding-bottom: $gutter * 2
    position: relative
    display: flex
    align-items: center
    flex-direction: column-reverse

    +l
      padding-left: $left
      flex-direction: row-reverse

  .labels-container
    +s
      margin-top: $gutter
      text-align: center
      width: 100%

    +l
      flex: 1
      padding-left: $gutter

  .labels
    min-height: var(--scroll-height)

    +s
      +flex-center

    p
      position: absolute
      transition: $transition-mid

      &:not(.active)
        +hidden

  .images
    width: size(184)
    height: size(400)
    position: relative
    margin: (size(14) + $gutter) size(15) size(14) size(14)
    background-color: $white
    z-index: 1

    +l
      flex: size(184) 0 0

    &:after
      content: ''
      display: block
      position: absolute
      top: size(-12)
      left: size(-12)
      width: size(208)
      height: size(420)
      background-image: url(../media/phone-overlay.svg)
      z-index: 1
      +shadow(3, $color: $white, $blur-factor: 4, $v: 0, $filter: true)

  .image
    +full-size-absolute
    transition: $transition-mid

    &:not(.active)
      +hidden

  .controls
    position: absolute
    bottom: 0
    display: flex
    justify-content: center
    width: size(208)

    +l
      left: $left

    li
      flex: size(15) 0 0
      cursor: pointer

      &:not(:first-child)
        margin-left: $gutter / 2

      &:after
        content: ''
        display: block
        +circle(size(15))
        border: solid size(1) transparentize($white, .5)
        transition: $transition-mid

      &.active
        &:after
          border-color: $tangerine
          background-color: $tangerine

  .bg
    top: auto
    height: size(635)
    bottom: $gutter * 2
    display: flex
    flex-direction: column-reverse

    +s
      display: none
