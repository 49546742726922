@function size($n)
  @return $n / 16 * 1rem

$size-1:   size(1)
$size-2:   size(2)
$size-4:   size(4)
$size-6:   size(6)
$size-8:   size(8)
$size-12:  size(12)
$size-16:  size(16)
$size-18:  size(18)
$size-24:  size(24)
$size-32:  size(32)
$size-36:  size(36)
$size-48:  size(48)
$size-64:  size(64)
$size-72:  size(72)
$size-84:  size(84)
$size-96:  size(96)
$size-128: size(128)
$size-144: size(144)
$size-196: size(196)
$size-256: size(256)

$padding-m: $size-24 !default
$padding-s: $size-12 !default