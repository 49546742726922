@import var

.field
  margin-top: $gutter
  font-size: size(16)
  --padding: #{size(19)} #{size(11)} #{size(9)}
  --label-transform: translateY(-5%) scale(.67)
  --label-transform-origin: .4em 0
  //border: solid size(1) transparent
  overflow: hidden

  +s
    margin-top: $gutter / 2

  &.left, .left
    display: flex
    align-items: baseline
    flex-wrap: wrap

    input
      width: auto

    span
      position: relative
      flex: 1
      padding-top: 0
      color: $deep-space
      white-space: normal
      --label-transform: none

    .validation-message
      width: 100%

  &.select
    &:after
      right: 1em
      top: 1.25em
      transform: scaleX(1.33) rotate(45deg)

  &.text
    cursor: auto

    span
      padding: 0
      position: relative

  &.error, form.invalid &
    .validation-message
      display: block

    input, textarea, select
      border-color: red

  input:not([type=checkbox]):not([type=radio]), textarea, button, select
    font-weight: 500
    border-radius: 0

  input:not([type=checkbox]):not([type=radio]):not([type=submit]), textarea, select
    background-color: $gray-bg !important // prevent autofill coloring
    border-bottom: solid size(1) $deep-space-80
    transition: $transition-quick

  textarea
    resize: vertical
    text-shadow: 0 0 .5em $gray-bg

  input[type=checkbox],
  input[type=radio]
    appearance: none
    background-color: transparent
    border-color: transparent
    $size: size(16)
    display: flex
    align-items: center
    width: 0
    height: 0
    margin-left: $size
    position: relative
    cursor: pointer

    &:before
      content: ''
      display: block
      background-color: $deep-space-15
      border: solid size(1) $deep-space-30
      +circle($size)
      transform: translate(size(-12), size(2))
      transition: $transition-quick

    &:after
      content: ''
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='line-v' overflow='visible' viewBox='0 0 18 15'%3E%3Cpath d='M1,9.54923599 L5.13280496,14 L10.0763549,8.58455442 M13.1686308,5.19709959 L17,1' id='Shape' stroke='%23FF6B40' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E")
      position: absolute
      left: size(14)
      bottom: size(4)
      width: size(17)
      height: size(14)
      transform: translate(size(-12), size(2))
      transition: $transition-quick
      transition-timing-function: ease-in
      +hidden

    &:checked
      &:before
        border-color: $tangerine-15
        background-color: $tangerine-15

      &:after
        opacity: 1

  input[type=checkbox]
    &:before
      border-radius: size(4)

  span
    padding: size(14)
    color: $deep-space-60
    white-space: pre

    &:empty
      display: none

  fieldset
    border: none
    display: flex
    flex-wrap: wrap
    justify-content: center
    padding: 0

  &.radio-group
    display: flex
    flex-direction: column-reverse
    pointer-events: none

    .radio
      pointer-events: auto
      margin: size(2) size(7)

    span
      position: relative
      padding-left: 0
      padding-top: 0
      color: $deep-space
      white-space: normal

    label
      padding-left: size(9)

  .validation-message
    order: -1
    padding-top: .5em
    display: none
    font-weight: 500

  &.submit
    button
      @extend .submit-button

  p
    @extend .small-text

  input:not([type=checkbox]):not([type=radio]):not([type=submit]), textarea
    &:not(:placeholder-shown)
      border-bottom-color: $deep-space-15

  input:not([type=checkbox]):not([type=radio]):not([type=submit]), textarea, select
    &:focus
      border-bottom-color: $saffron
      outline: none

  select:valid
    border-color: $deep-space-15

.field-row
  .form-container form:first-child &:first-child
    margin-top: -$gutter

    +s
      margin-top: -$gutter / 2

  +l
    display: flex

    > *:not([hidden])
      flex: 1

      &:not(:first-child)
        margin-left: $gutter

.mktoForm
  +visually-hidden

form
  margin: 0

  &.pending
    button
      pointer-events: none
      opacity: .25

  .small-text
    font-size: size(12)
    color: $gray-dark

  .submit-button
    font-size: size(21)
    background-color: $tangerine
    color: $white
    padding: 1em
    text-align: center
    transition: $transition-quick
    width: 100%
    border-color: transparent
    cursor: pointer
    white-space: normal

    &:hover
      background-color: lighten($tangerine, 5%)

.wpcf7
  br
    display: none

  label
    @extend .field

  input[type=submit]
    @extend .submit-button

  .ajax-loader
    display: block !important
    position: relative !important
    transform: none !important
    background:
      size: contain
      position: center
    width: 100% !important
    margin: $gutter / 2 0 0 !important

span.mktoErrorDetail
  position: static
  display: inline
  padding: 0

span.wpcf7-list-item
  margin-left: 0

  > label
    margin-top: 0

.wpcf7-form-control
  display: block
  +flex-center(row)