@import var

body
  font-family: 'Wotfard', 'Helvetica', sans-serif
  font-feature-settings: 'salt'
  color: $gray-dark
  font-size: size(18)

p
  line-height: 1.35

p,h1,h2,h3,h4,h5,h6
  + p, + * > p:first-child
    margin-top: .66em

h1,h2,h3,h4,h5,h6,
.title, a
  color: $deep-space
  font-weight: 500

b, strong
  font-weight: 500

.title-large
  &, p
    font-size: size(54)

    +small
      font-size: size(36)

.title-mid
  &, p
    font-size: size(42)
    line-height: 1.15

    +small
      font-size: size(27)

.title-small
  &, p
    font-size: size(30)
    line-height: 1.05

    +small
      font-size: size(24)

.title-label
  margin-bottom: $gutter

  &, p
    text-transform: uppercase
    font:
      size: size(20)
      weight: 500
    color: $deep-space-45
    letter-spacing: .1em
    line-height: 1.25

    +small
      font-size: size(17)

    &.small
      font:
        size: size(16)
        weight: 600

      +small
        font-size: size(14)

.text-large
  &, p
    font-size: size(25)
    line-height: 1.32

    +small
      font-size: size(21)

.text-small
  &, p
    font-size: size(14)

.title-large + .text
  margin-top: $gutter

+color-classes('text', '')

a
  transition: $transition-quick

  +hover
    color: $tangerine