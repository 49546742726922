//noinspection CssOverwrittenProperties
=scroll-height-auto($init: false)
  height: auto
  @if ($init)
    height: var(--scroll-height, $init)
  @else
    height: var(--scroll-height, auto)

//noinspection CssOverwrittenProperties
=window-height-100vh
  height: 100vh
  height: var(--window-height, 100vh)

=circle($size)
  width: $size
  height: $size
  border-radius: $size / 2
  overflow: hidden
  flex-shrink: 0