@import var

main.references
  .hero
    .text-large
      font-weight: 500

    .graphic
      +s
        margin-top: $gutter

section.customers
  .content
    +l
      display: flex

      .texts
        flex: cols(3) 0 0
        margin-right: $gutter

      .logos
        flex: cols(9) 0 0
        margin-top: -$gutter / 2

  .logos
    display: flex
    flex-wrap: wrap

  .logo
    flex: cols(1.8) 0 0
    margin: $gutter / 4 0
    +flex-center

    +s
      flex: 50% 0 0

    img
      width: cols(1.5)