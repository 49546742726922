@import var

section.text-block
  &.type-title-text
    .texts
      +l
        +flex-columns($gap: auto)

    h2
      padding-right: $gutter

    +s
      margin-bottom: $gutter * 2

  &.type-graphic
    @extend .full
    overflow: hidden

    .texts
      .content:last-of-type
        +l
          columns: 2
          column-gap: $gutter

        p
          break-inside: avoid

        .text, header
          > *:first-child
            margin-top: 0

  &.type-ticket
    @extend .full
    background-color: $deep-space

    .product-ticket
      margin-top: calc(-1 * var(--padding-big-size))
      margin-bottom: $gutter * 2

    +l
      &:before
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        width: calc((100vw - #{grid-width(12)}) / 2 + #{cols(1) + $gutter * 2})
        background-color: $white

    &, *
      color: $white

    .texts
      +l
        max-width: cols(7)
        margin-left: cols(2)

      a
        @extend .white

  &:not(.type-title-text)
    .content
      + .content
        margin-top: $gutter

  .graphic
    position: absolute
    top: -$gutter * 2
    right: -$gutter * 8
    max-height: $size-196
    align-items: flex-end

    +s
      display: none

    svg:not(.super-underline-svg), img
      height: 100%
      width: auto
      min-height: 0
      object-position: right

  a
    margin-top: $gutter