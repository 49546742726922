@import var

section.testimonials
  > .content
    display: flex
    margin-top: $gutter-big
    height: var(--current-height, auto)
    transition: $transition-short

    +s
      margin-top: 0

    > *
      +l
        margin-top: auto

  &.gallery
    > .content
      &.timer
        +scroll-height-auto(calc(400px - var(--padding-big-size)))

  .logo
    flex: 1
    pointer-events: none
    mix-blend-mode: multiply

    img
      width: $size-196
      height: $size-72
      object-fit: contain
      object-position: top left

    +l
      flex: cols(3) 0 0
      width: cols(3)
      padding-right: cols(1)

    +s
      margin-top: $gutter

  .testimonial.in-gallery
    .content
      position: absolute
      top: 0
      bottom: 0
      right: $gutter
      left: $gutter
      pointer-events: none

      +s
        .texts
          margin-top: $gutter-big

  .testimonial
    .content
      +l
        display: flex
        flex-direction: row-reverse
        align-items: flex-start

  .texts
    +l
      flex: 1 //cols(9) 0 0
      max-width: cols(9)
      display: flex
      flex-direction: column

    +s
      margin-top: $gutter

      .title-mid
        p
          line-height: 1.35

      .text-large
        p
          font-size: size(18)

  .link
    margin-top: auto
    align-self: flex-end

  a
    margin-top: var(--padding-big-size)

  label
    display: block
    +circle($size-16)
    border: solid size(1) $deep-space
    margin-right: size(20)
    opacity: .5
    transition: $transition-quick

    +s
      margin-top: 0 //$size-72 / 2 - $size-16 / 2

  blockquote, .name, a, .logo
    transform: translateZ(0)

  input:not(:checked) + .testimonial
    .content
      +hidden

  input:checked + .testimonial, .testimonial:not(.in-gallery)
    .content
      +l
        blockquote, .name, a, .logo
          animation: testimonial-in $duration-mid $ease 1 backwards
          @keyframes testimonial-in
            from
              opacity: 0
              transform: translateX(-$gutter / 2)

        a
          animation-delay: $duration-mid - $duration-quick

    label
      opacity: 1
      background-color: $deep-space

    .content
      > *:not(.logo)
        pointer-events: auto