@import var

main.about
  .hero
    +s
      margin-top: -$gutter
      padding-top: $gutter * 2

      .content
        flex-direction: column

      .graphic
        display: none
        padding: $gutter $gutter-big

section.history
  .super-underline
    @extend .underline-tangerine

  +l
    &:after
      content: ''
      position: absolute
      bottom: -$gutter-big
      right: 0
      width: size(553)
      height: size(304)
      background-image: url('../media/about-timeline.png')

  ul
    +l
      margin-top: $gutter-big
      position: relative

      &:before
        content: ''
        +full-size-absolute($auto: right)
        width: size(1)
        background-color: $deep-space-30

  li
    margin-bottom: $gutter
    border-top: solid size(1) transparentize($white, .75)

    +l
      width: cols(5)
      padding-left: $gutter * 2

  .texts
    transition: $transition-quick
    +scroll-height-auto
    margin-top: $gutter
    font-weight: 500

    +l
      position: absolute
      top: 0
      width: cols(6)
      right: $padding-s
      margin-top: 0

      > *
        transform: translateZ(0)

  h3
    font-weight: 600
    transition: $transition-quick
    margin-left: 0

    +l
      display: flex
      align-items: center

      &:before
        content: ''
        display: block
        position: absolute
        left: size(-14/2)
        +circle(size(14))
        background-color: $deep-space
        transition: $transition-quick

  input:not(:checked) ~ .texts
    +hidden

    +s
      height: 0

    .super-underline
      &:after
        --url: none

  input:checked ~ .texts
    +l
      animation: history-in $duration-mid $ease 1 backwards
      @keyframes history-in
        from
          opacity: 0
          transform: translateY($gutter / 2)

  input:not(:checked) ~ h3
    opacity: .25

    &:hover
      opacity: .5

    &:before
      +hidden

section.locations
  svg
    //stroke: var(--color)

  .card
    header
      margin-bottom: $gutter
      +flex-space

section.teams
  .team
    margin-top: $gutter-big

    +l
      margin-top: $gutter-big * 2

  ul
    +l
      +flex-columns(4, $gap: auto)

  .person
    color: $deep-space
    margin-bottom: var(--padding-big-size)

    .image
      height: cols(3)

    h3
      margin-top: $gutter

    p
      margin-top:  .33em