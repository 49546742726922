@import var

section.table
  table
    width: 100%

  th
    text-align: left
    position: sticky
    top: calc(#{$header-height} - 1px)
    background-color: transparentize($white, .075)

  th, td
    padding: $gutter / 2

  .dot
    display: block
    +circle(size(18))
    background-color: $tangerine