@import var

main.services
  .hero
    .text-large
      font-weight: 500

  .bordered
    margin: $gutter  0

    +l
      margin: $gutter * 2 0

  .video-container
    .text
      @extend .text-large

  .values
    .title-mid
      margin-bottom: $gutter * 2

    li:not(:first-child)
      margin-top: $gutter

    .text
      margin-top: .33em

  .recruiting-process
    ul
      color: $aqua
      font-weight: 600

      +l
        display: flex
        justify-content: space-between

    li
      +l
        display: flex
        align-items: center

      p
        background-color: $aqua-15
        padding: $gutter / 2
        +flex-center
        text-align: center

        +l
          height: 100%

      &:not(:last-child)
        &:after
          content: '›'
          display: block
          width: $gutter
          +flex-center


          +s
            width: 100%
            height: $gutter
            writing-mode: tb