@import var

section.our-products
  .title-label
    opacity: .5

  .title-small
    font-weight: 600
    margin-bottom: $gutter

  .products
    +l
      @for $i from 1 through 5
        &.mod-#{$i}
          +flex-columns($i, auto)

  .product, a
    margin-top: $gutter

  .product
    display: flex
    flex-direction: column

    .link
      margin-top: auto

    &:not(:first-child)
      +s
        margin-top: $gutter * 2

  .platform
    margin-top: $gutter * 2
    padding-top: $gutter
    border-top: solid size(1) $tangerine-45

    a
      font-weight: 400