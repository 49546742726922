@import var

main.product
  .hero
    z-index: 2

    a
      margin-top: $gutter

    +l
      .texts
        +flex-space(column, flex-start)

      a
        margin-bottom: $gutter-big

    .product-ticket
      flex-direction: column
      margin-top: $gutter
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: size(-8)
      width: size(164)

      +s
        display: none

      .ticket-texts
        margin-left: 0
        margin-top: $gutter / 2

      .text-large
        font-size: size(22)

    .bg
      z-index: -1
      margin: -$gutter * 2 0

      +s
        display: none

    .graphic
      svg, img
        z-index: -1

        +s
          position: absolute
          // bottom: 0
          width: size(250)
          max-width: 100%

  .video
    width: size(222)
    height: size(480)
    position: relative
    margin: -$gutter size(17) (-$gutter) size(16)
    background-color: $white
    contain: layout
    transform: translateZ(0)

    +s
      margin: size(-16) size(17)
      transform: scale(.67)

    &:after
      content: ''
      display: block
      position: absolute
      top: size(-16)
      left: size(-17)
      width: size(256)
      height: size(510)
      background-image: url(../media/phone-overlay.svg)
      z-index: 1

    &:before
      content: ''
      display: block
      position: absolute
      top: size(-16 - 27)
      left: size(-17 - 90)
      width: size(370)
      height: size(599)
      background-image: url(../media/phone-shadow.png)
      z-index: -1

    video
      object-fit: cover
      height: 100%
      width: 100%

  .overview
    strong
      @extend .text-large
      @extend .text-deep-space
      font-weight: 500

    h2
      margin-top: $gutter * 2
      margin-bottom: $gutter

    +l
      .texts
        &.with-features
          +flex-columns($gap: auto)

        &:not(.with-features)
          .text
            columns: 2
            column-gap: $gutter

            p
              break-inside: avoid
      .icons
        padding-left: cols(1)
        +flex-space(column, flex-start)

        li:last-of-type
          margin-bottom: $gutter / 2

        .icon
          margin-left: -$gutter
          margin-right: $gutter

  .benefits
    ul
      +s
        li
          margin-top: $gutter

      +l
        margin-top: $gutter-big
        +flex-columns(4, $gap: auto)

        li
          padding-right: $gutter