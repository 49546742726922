@import var

@keyframes svg-stroke-in
  0%
    transform: scale(0)
    stroke-dashoffset: 1

  33%
    transform: scale(1)
    stroke-dashoffset: .99

@keyframes svg-scale-in
  from
    transform: scale(0)

@keyframes svg-mask-in
  0%, 33%
    transform: scaleX(0)

@keyframes svg-opacity-in
  0%, 33%
    opacity: 0

.graphic-elements
  +l
    position: absolute
    width: unset
    left: 0
    transform: var(--transform-l)

  .drawn
    display: none

  &.in-view
    .drawn
      display: revert
      transform-box: fill-box
      stroke-dasharray: 1
      stroke-dashoffset: 0
      animation: svg-stroke-in calc(var(--duration, 1) * 3.4s) cubic-bezier(.69, 0, 0, 1) backwards calc(var(--i, 1) * .225s) 1

      &.scale
        animation-name: svg-scale-in

      &.mask
        animation-name: svg-mask-in

      &.opacity
        animation-name: svg-opacity-in

#global-svg
  display: none

.graphic-element-container
  pointer-events: none
  overflow: hidden
  position: absolute

  .content &
    left: calc(-1 * (100vw - #{grid-width(12)}) / 2)
    right: calc(-1 * (100vw - #{grid-width(12)}) / 2)
    top: calc(-2 * var(--padding-big-size))
    bottom: calc(-2 * var(--padding-big-size))
    padding: var(--padding-big-size) grid-padding()

  &.side
    +smaller-than-grid
      display: none

  svg, img
    position: absolute
    left: var(--left)
    top: calc(var(--top) + var(--padding-big-size))
    right: var(--right)
    bottom: calc(var(--bottom) + var(--padding-big-size))
    width: var(--width)
    height: var(--height)