@import var

// sizes

$small: 768px !default
$medium: 1024px !default
$large: 1920px !default


// size queries

=small
  @media screen and (max-width: $small - 1)
    @content

=medium
  @media screen and (min-width: $small)
    @content

=large
  @media screen and (min-width: $medium)
    @content

=huge
  @media screen and (min-width: $large + 1)
    @content

=portrait
  @media screen and (orientation: portrait)
    @content

=landscape
  @media screen and (orientation: landscape)
    @content

=portrait-or-small
  @media screen and (orientation: portrait), (max-width: $small - 1)
    @content

=not-portrait-or-small
  @media screen and (orientation: landscape) and (min-width: $small)
    @content

=portrait-medium
  @media screen and (orientation: portrait) and (min-width: $small) and (max-width: $medium - 1)
    @content

=landscape-medium
  @media screen and (orientation: landscape) and (min-width: $small) and (max-width: $medium - 1)
    @content

//noinspection CssInvalidFunction
=horizontal-with-safe-area
  @supports (padding: env(safe-area-inset-left))
    +landscape
      @content


// current-device queries
// https://github.com/matthewhudson/current-device

=device-phone
  html.mobile &
    @content

=device-tablet
  html.tablet &
    @content

=device-desktop
  html.desktop &
    @content

=device-mobile
  html.mobile &
    @content

  html.tablet &
    @content

=device-portrait-device
  html.mobile &
    @content

  html.tablet.portrait &
    @content

=device-tablet-portrait
  html.tablet.portrait &
    @content

=device-tablet-landscape
  html.tablet.landscape &
    @content


// browser detection hacks

=browser-edge
  @supports (-ms-ime-align: auto)
    @content

=browser-not-edge
  @supports not (-ms-ime-align: auto)
    @content

=browser-firefox
  @-moz-document url-prefix()
    @content

=browser-safari
  @media not all and (min-resolution: .001dpcm)
    @supports (-webkit-appearance: none)
      @content


// user preferences

=dark
  @media (prefers-color-scheme: dark)
    @content

=light
  @media (prefers-color-scheme: light)
    @content

=motion
  @media (prefers-reduced-motion: no-preference)
    @content

=reduced-motion
  @media (prefers-reduced-motion: reduce)
    @content