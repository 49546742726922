@import var

.site-footer
  background-color: $gray-bg
  padding: var(--padding-big)
  padding-bottom: $gutter
  font-size: size(16)

  *
    color: $deep-space-80

    a *
      color: inherit

  li
    list-style: none

  .title-label
    font-weight: 500

  .info
    +l
      +flex-columns(4, $gap: auto)

    +s
      > *:not(:first-child)
        margin-top: $gutter * 2

      .title-label
        margin-bottom: $gutter / 2

    .links
      flex: cols(4) + $gutter 0 1

      +l
        padding-bottom: size(108)

    ul
      columns: 2
      column-gap: $gutter

    li
      break-inside: avoid
      margin-bottom: $gutter / 2

    a
      text-decoration: underline
      font-weight: normal

  .legal
    font-size: size(15)

    &, *
      color: transparentize($deep-space, .6)

    .content
      margin-top: $gutter
      padding-top: $gutter
      border-top: solid size(1) $gray-light

      +l
        +flex-space

    .links
      +l
        display: flex

      +s
        > *
          display: block
          margin-top: $gutter / 2

      a
        text-decoration: none

        +l
          margin-left: $gutter /  2

    a
      &, *
        transition: $transition-quick

      &:hover
        &, *
          color: $deep-space

    .credit-logo
      +s
        margin-top: $gutter