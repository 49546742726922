@import utils/partials

// brand
$colors: ('tangerine': #FF6B40, 'deep-space': #1c1f38, 'aqua': #4FB2B2, 'saffron': #F7B53D, 'gray-dark': #575757, 'gray-light': #E6E6E6, 'gray-bg': #FAFAFA)

@function color($color)
  @return map-get($colors, $color)

// generate classes for each color with specific properties
=color-classes($class-prefix: null, $selector-prefix: '&', $properties: ('color', '--color'))
  @if $class-prefix
    $class-prefix: $class-prefix + '-'

  @each $color, $value in $colors
    #{$selector-prefix}.#{$class-prefix}#{$color}
      @each $prop in $properties
        #{$prop}: $value

$tangerine: #FF6B40
$tangerine-45: #FFBDAB
$tangerine-30: #FFD4C7
$tangerine-15: #FFE8E2
$deep-space: #1c1f38 //121936
$deep-space-80: #484A63
$deep-space-60: #74768A
$deep-space-45: #9698A8
$deep-space-30: #B8BCC8
$deep-space-15: #D8DCE3
$aqua: #4FB2B2
$aqua-80: #73C2C2
$aqua-15: #E4F3F3
$saffron: #F7B53D
$gray-dark: #575757
$gray-45: #B3B3B3
$gray-light: #E6E6E6
$gray-bg: #FAFAFA

// sizes
$gutter: $size-24
$gutter-big: cols(1)
$header-height: $size-72

$shadow-header: 0 2px 4px -1px rgba(0,0,0,0.03), 0 3px 12px 0 rgba(0,0,0,0.06)
$shadow-header-inset: inset 0 2px 4px -1px rgba(0,0,0,0.03), inset 0 3px 12px 0 rgba(0,0,0,0.06)
$shadow-card: 0 2px 4px 0 rgba(0,0,0,0.03), 0 3px 12px 0 rgba(0,0,0,0.06), 0 12px 32px 0 rgba(0,0,0,0.09)

// overrides
$grid-gutter-width: $gutter
$grid-col-width: size(74)
$padding-s: $gutter
$padding-m: $gutter

=l
  +larger-than-grid
    @content

=s
  +smaller-than-grid
    @content

=hover
  +l
    &:hover
      @content

=de
  html[lang=de-DE] &
    @content